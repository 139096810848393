<template>
  <div id="datos-tramite">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-text
          style="background: #eee; overflow-y: scroll; max-height: 72vh"
        >
          <v-row class="mt-2">
            <v-col cols="12" md="12" sm="12">
              <base-material-card color="primary" class="px-5 py-3">
                <template v-slot:heading>
                  <div class="text-h5 font-weight-light my-2">
                    Registro Seccional Correspondiente
                  </div>
                </template>
                <div class="text-center grey-lighten-2 my-2">
                  <p
                    class="text-h5 indigo--text text--darken-4 font-weight-bold mb-0"
                  >
                    Para consultar el registro donde deberá presentar el trámite
                    debe ingresar el domiciolio del Titular o Acreedor
                    Prendario.
                  </p>
                  <p class="text-h6 black--text font-weight-light text-center">
                    (En caso que no se pueda obtener el registro según los datos
                    cargados, el Comerciante Habitualista deberá seleccionar el
                    registro donde presentará el tramite)
                  </p>
                </div>

                <v-card-text class="py-6">
                  <v-row class="mx-0 justify-center" dense>
                    <v-col
                      cols="12"
                      md="6"
                      sm="12"
                      :style="{
                        borderRadius: '5px',
                        backgroundColor: $cv('primary') + '20',
                      }"
                    >
                      <v-row class="px-3 py-3">
                        <v-col cols="12" md="12" sm="12" class="py-0">
                          <v-autocomplete
                            :items="provincias"
                            item-value="id"
                            item-text="name"
                            label="Provincia"
                            v-model="form.provincia"
                            :rules="[$rulesRequerido]"
                            return-object
                          ></v-autocomplete
                        ></v-col>

                        <v-col cols="12" md="6" sm="6" class="py-0">
                          <v-text-field
                            v-model="form.depto"
                            label="Departamento"
                            :rules="[$rulesRequerido, $rulesAlfaNum]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="6" class="py-0">
                          <v-text-field
                            v-model="form.localidad"
                            label="Localidad"
                            :rules="[$rulesRequerido, $rulesAlfaNum]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="10" sm="10" class="py-0">
                          <v-text-field
                            v-model="form.calle"
                            label="Calle"
                            :rules="[$rulesRequerido, $rulesAlfaNum]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="2" sm="2" class="py-0">
                          <v-text-field
                            v-model="form.numero"
                            label="Altura Exacta"
                            :rules="[$rulesRequerido, $rulesEnterosPositivos]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="12" sm="12" class="py-0">
                          <v-textarea
                            v-model="form.observaciones"
                            label="Observaciones"
                            auto-grow
                            rows="4"
                            row-height="15"
                            dense
                            clearable
                            clear-icon="mdi-close-circle"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" md="6" sm="6" class="offset-md-3">
                          <v-btn
                            color="primary"
                            @click="calculate()"
                            style="width: 100%"
                            >Calcular</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row :key="keyRegistro" dense>
                        <v-col
                          cols="12"
                          md="12"
                          sm="12"
                          class="text-h5 text-center px-2 py-2"
                        >
                          <hr :hidden="hrHidden" />
                          <div :class="selectRegister">
                            <p style="margin-bottom: 0">
                              El Registro Seccional Correspondiente es:
                            </p>
                            <div
                              v-if="form.registro"
                              class="text-h5 font-weight-light my-2 blue--text"
                            ></div>
                            <div
                              class="text-h5 indigo--text text--darken-4 font-weight-bold"
                            >
                              {{ form.registro }}
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </base-material-card>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="text-right" cols="12" md="12" sm="12">
              <v-btn color="red" @click="$exit(idTramite)">Salir</v-btn>
              <v-btn color="primary-2" @click="back()">Atras</v-btn>
              <v-btn color="primary" @click="next()">Vista Previa</v-btn>
            </v-col>
          </v-row>
          <br />
        </v-card-text>
      </v-card>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<style scoped>
.right-input >>> input {
  text-align: right;
}
.chip-indicadores {
  color: "blue-grey--text darken-4";
  font-size: 14px;
  /*font-family: 'Material Icons';*/
  /*font-weight: bold;*/
  font-style: normal;
}

.primary-change {
  color: #fff !important;
  /*background-color: #a7a8a8 !important;*/
  background-color: #105cfa !important;
  font-size: 20px;
  font-weight: bold;
  height: 70px !important;
  display: flex;
  align-items: center;
}
.col-f12 {
  text-align: center;
  font-size: 2rem;
  /*border-radius: 28px;*/
  font-style: #a0a0a0 !important;
  color: #a0a0a0 !important;
  font-weight: 500 !important;
  border-bottom: solid;
  border-color: #011f5b;
  /*border-radius: 28px;*/
  /*background: #eee;*/
  padding: 8px 0px 23px 17px;
  height: 55px;
  margin-top: 7px;
  margin-left: 7px !important;
  margin-right: 15px;
}
.centered-input >>> input {
  text-align: center;
  font-size: 1.5rem;
  color: #105cfa !important;
  margin-top: 16px;
  border-bottom: solid;
  border-color: #011f5b;
}
.calcular {
  height: 6rem;
}
.calculado {
  background-color: white;
}
</style>
<script>
export default {
  name: "DatosRegistro",
  components: {},
  props: {
    tramite: { Object, default: null },
    datosRegistro: { Object, default: null },
    tipoF01: { String, default: null },
    idTramite: { Number, default: null },
  },
  data: () => ({
    provincias: [
      { id: 1, name: "CAPITAL FEDERAL" },
      { id: 2, name: "BUENOS AIRES" },
      { id: 3, name: "SANTA FE" },
      { id: 4, name: "CORDOBA" },
      { id: 5, name: "MENDOZA" },
    ],
    barrios: [
      { id: 1, name: "CABA" },
      { id: 2, name: "EZEIZA" },
      { id: 3, name: "MONTE GRANDE" },
      { id: 4, name: "RAMOS MEJIA" },
      { id: 5, name: "SAN JUSTO" },
    ],
    snackbar: false,
    color: "success",
    timeout: 4000,
    text: "Se ha registrado una nueva solicitud",
    form: {},
    arrayRegistros: [
      "Registro Seccional N 01223. Direccion: 9 de Julio 231, Ezeiza, Provincia de Buenos Aires",
      "Registro Seccional N 02711. Direccion: Monseñor José Francisco Marcón, San Justo, Provincia de Buenos Aires",
      "Registro Seccional N 12091. Direccion: Gral. Ocampo 3087, B1754 San Justo, Provincia de Buenos Aires",
      "Registro Seccional N 55667. Direccion: Olegario Víctor Andrade 5960, B1757 Gregorio de Laferrere, Provincia de Buenos Aires",
    ],
    keyRegistro: 0,
    userForm: {},
    routeUser: "userAuthRoute",
    selectRegister: "calcular",
    hrHidden: false,
  }),

  methods: {
    back() {
      this.$emit("send-message", "5");
    },
    async calculate() {
      if (this.$refs.form.validate()) {
        const { value: password } = await Swal.fire({
          title: "Para consultar el registro deberá ingresar su contraseña",
          icon: "warning",
          input: "password",
          inputPlaceholder: "Ingrese su contraseña",
          inputAttributes: {
            /*maxlength: 10,*/
            autocapitalize: "off",
            autocorrect: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
          confirmButtonColor: "green",
          cancelButtonColor: "#d33",
          inputValidator: (value) => {
            if (!value) {
              return "Ingrese su contraseña";
            }
          },
        });

        if (password) {
          Swal.alertGetInfo("Consultando registro. Aguarde un instante");

          this.userForm.email = this.$keycloak.tokenParsed.preferred_username;
          this.userForm.password = password;
          this.$axiosApi
            .post(this.routeUser, this.userForm)
            .then((r) => {
              if (
                typeof r.data !== "undefined" &&
                r.data !== null &&
                r.data.code == 200
              ) {
                this.getRegistro();
              } else {
                Swal.close();
                Swal.alertError(
                  "Error de Autenticación",
                  "Acceso inválido. No puede consultar"
                );
              }
            })
            .catch((e) => {
              Swal.close();
              //console.log(e);
              Swal.alertError(
                "Error al Consultar el registro",
                "Ha ocurrido un error al intentar consultar el registro"
              );
            });
        }
      }

      /*


								        Swal.fire({
								          title: "<b>Consultar Registro Seccional?</b>",
								          icon: "question",
								          html:
								            "Desea buscar el registro para el siguiente domicilio: " +
								            "<b>" +
								            this.form.calle +
								            " " +
								            this.form.numero +
								            ". " +
								            this.form.localidad +
								            ". " +
								            this.form.depto +
								            ". " +
								            this.form.provincia.name +
								            "</b>",
								          showCloseButton: false,
								          showCancelButton: true,
								          focusConfirm: true,
								          confirmButtonColor: "green",
								          cancelButtonColor: "#d33",
								          confirmButtonText: "Aceptar",
								          cancelButtonText: "Cancelar",
								        }).then((result) => {
								          if (result.isConfirmed) {
								            Swal.alertGetInfo("Buscando datos del Registro Seccional");
								            this.getRegistro();
								          }
								        });
								      } else {
								        this.snackbar = true;
								        this.text = "Verifique los datos ingresados y/o campos obligatorios";
								        this.color = "error";
								      }*/
    },
    async getRegistro() {
      await this.$sleep(1000);

      Swal.close();
      let aleatorio =
        this.arrayRegistros[
          Math.floor(Math.random() * this.arrayRegistros.length)
        ];
      //console.log(aleatorio);
      this.form.registro = aleatorio;
      this.keyRegistro = this.keyRegistro + 1;
      Swal.fire({
        icon: "success",
        title: "Consulta de Registro Seccional",
        confirmButtonText: "Aceptar",
        html:
          "El Registro que le corresponde a su dirección es: " +
          "<b>" +
          aleatorio +
          "</b>",
      });
      this.hrHidden = true;
      this.selectRegister = "calculado";
      /*
																																																											      this.$axiosApi
																																																											            .get(this.route)
																																																											            .then((r) => {
																																																											              if (typeof r !== "undefined" && r !== null) {
																																																											                Swal.close();
																																																											                Swal.fire({
																																																											                  icon: "success",
																																																											                  title: "Consulta de Registro Seccional",
																																																											                  text: "Se obtuvieron los datos del Registro Correspondiente",
																																																											                });
																																																											              } else {
																																																											                Swal.close();
																																																											                Swal.fire({
																																																											                  icon: "error",
																																																											                  title: "Consulta de Registro Seccional",
																																																											                  text: "Ha ocurrido un error al intentar consultar el registro seccional",
																																																											                });
																																																											              }
																																																											            })
																																																											            .catch((e) => {
																																																											              console.log(e);
																																																											              Swal.fire({
																																																											                icon: "error",
																																																											                title: "Consulta de Registro Seccional",
																																																											                text: "Ha ocurrido un error al intentar consultar el registro seccional",
																																																											              });
																																																											            });*/
    },
    async next() {
      //if (this.$refs.form.validate() && this.primeravez) {
      if (this.$refs.form.validate()) {
        if (this.form.registro) {
          this.$emit("send-message", "7", "6", this.form);
        } else {
          this.snackbar = true;
          this.text =
            "Debe calcular el registro seccional correspondiente a su dirección";
          this.color = "error";
        }
        //this.$emit("send-message", "7", "6", this.form);
      } else {
        this.snackbar = true;
        this.text = "Verifique los datos ingresados y/o campos obligatorios";
        this.color = "error";
      }
    },
    setDatosPrueba() {
      this.form.calle = "LAVALLE";
      this.form.numero = "1234";
      this.form.provincia = { id: 1, name: "CAPITAL FEDERAL" };
      this.form.depto = "EZEIZA";
      this.form.localidad = "JOSE MARIA EZEIZA";
    },
    resultadoPrecargado() {
      if (this.form.registro) {
        this.selectRegister = "calculado";
        this.hrHidden = true;
      }
    },
  },
  computed: {},
  created() {
    //this.setDatosPrueba();

    this.valid = true;
  },

  watch: {},
  mounted() {
    if (Object.keys(this.datosRegistro).length) {
      console.log(this.datosRegistro);
      this.form = this.datosRegistro;
    }
    this.resultadoPrecargado();
    console.log("Componente Datos Tramite creado");
  },
};
</script>
